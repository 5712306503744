<template>
    <div class="relative pr-3">
        <el-badge :value="notificationsNumber" class="notification-icon" :hidden="!notificationsNumber">
            <fa-icon :icon="['far', 'bell']" class="text-xl mt-1 cursor-pointer text-gray-600" @click="visible = !visible" />
        </el-badge>
        <div v-show="visible" class="absolute right-0 bg-white rounded-lg shadow-md border w-112 list-container">
            <div v-for="notification in notifications.slice(0, 5)" :key="notification.id" class="flex relative py-2 px-4 border-b notification-item">
                <p class="flex mr-4 items-center">
                    <!-- <p class="mr-4">{{ notification.data.icon }}</p> -->
                    <fa-icon v-if="notification.data.icon === 'info'" :icon="['fas', 'info-circle']" class="text-4xl text-blue-200" />
                    <fa-icon v-if="notification.data.icon === 'warning'" :icon="['fas', 'exclamation-circle']" class="text-4xl text-yellow-200" />
                </p>
                <div>
                    <p class="text-xs text-gray-800 mb font-semibold">
                        {{ notification.data.title }}
                    </p>
                    <p class="text-xs text-gray-600">
                        {{ notification.data.content }}
                    </p>
                </div>
                <div class="flex flex-col items-end justify-between">
                    <p class="text-xs text-gray-400">
                        {{ notification.data.time }}
                    </p>
                    <p class="text-xs text-gray-400">
                        {{ notification.data.date }}
                    </p>
                </div>
                <div
                    class="flex absolute items-center right-0 top-0 bg-white h-full border-l transition cursor-pointer
                    rounded-tr-lg check-btn text-green-200 hover:text-green-400"
                    @click="markAsSeen(notification.id)"
                >
                    <fa-icon :icon="['fas', 'check-circle']" class="text-3xl mx-6" />
                </div>
            </div>

            <div v-if="!notifications.length" class="p-6 border-b text-center">
                <p class="text-xs text-gray-600">
                    {{ $t('common.there_is_no_new_notifications') }}
                </p>
            </div>

            <div
                class="w-full py bg-gray-100 rounded-b-lg hover:bg-gray-200 cursor-pointer text-gray-500 hover:text-gray-600 transition"
                @click="visible = false"
            >
                <router-link to="/notifications" class="block text-xs text-center">
                    {{ $t('common.show_all') }}...
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
import Favico from 'favico.js';
import Vue from 'vue';
import { Badge } from 'element-ui';
import Notify from 'notifyjs';

Vue.use(Badge);

const favicon = new Favico({
    animation: 'slide',
});

export default {
    data() {
        return {
            visible: false,
        };
    },

    computed: {
        notifications() {
            return this.$store.state.user.user.notifications;
        },
        notificationsNumber() {
            return this.notifications.length;
        },
        userUuid() {
            return this.$store.state.user.user.user_uuid;
        },
        userId() {
            return this.$store.state.user.user.user_id;
        },
    },

    watch: {
        notifications() {
            favicon.badge(this.notificationsNumber);
        },
        userUuid() {
            if (!this.userUuid) return;
            this.initPusher();
        },
    },

    created() {
        this.initPusher();
        if (Notify.needsPermission) {
            Notify.requestPermission();
        }
    },

    methods: {
        initPusher() {
            if (!this.userUuid) return;
            window.Echo.private(`App.Models.User.${this.userUuid}`)
                .notification(notification => {
                    // this.$store.commit('user/pushNewNotification', notification);
                    // this.showNotification();

                    if (notification.type === 'calendarEventsChanged') {
                        this.$store.commit('calendar/incrementCacheIndex');
                    }
                });

            window.Echo.private(`ChatMessages.${this.userId}`)
                .listen('.NewChatMessage', e => {
                    this.showNotification(this.$t('new_chat_message'));
                    this.$store.commit('incrementUnreadMessagesForRoom', e.message.room_id);
                    this.$store.commit('updateLastChatMessage', e.message);
                });
        },

        showNotification(title) {
            const newNotification = new Notify('Workflows', {
                body: title,
                icon: '/production_favicon.png',
            });
            newNotification.show();
        },

        async markAsSeen(uuid) {
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/notifications/mark_as_read/${uuid}`);
            this.$store.commit('user/removeNotificationFromUnseen', uuid);
            this.$notify.success({ title: 'Success' });
        },
    },
};
</script>
<style scoped>
    .notification-icon {
        margin-top: -10px;
    }

    .notification-item .check-btn {
        opacity: 0;
    }

    .notification-item:hover .check-btn {
        opacity: 1;
    }

    .list-container {
        z-index: 9999;
    }
</style>
<style>
    .notification-icon .el-badge__content {
        height: 20px;
    }
</style>
